import {useEffect, useState} from 'react';
import {assignUserToGroup, createOrUpdateUserGroup, getLicensingUserGroupList, getUsersList} from "./utils/licensing";
import {useDebounce} from "./useDebounce";
import {slugify} from "./helpers";  // Replace with the correct path

const useLicensingUsers = ({search="", page=1, size=10}) => {

    const getUsersForSelectDropdown = (input, callback) => {
       return getUsersList( {
            limit:size,
            page,
            query:input,
        }, callback, callback)
    }

    const createUserGroupsAndAssignUsers = ({groupName, users}, successCallback, errorCallback) => {
        const groupID = slugify(groupName)+'-'+Date.now()
        createOrUpdateUserGroup({
            name:groupName,
            group_id:groupID
        }, (status)=>{
            if([201, 200].includes(status)){
                handleAssignUserToGroup({groupID, users}, successCallback, errorCallback)
            }else{
                errorCallback("An error occurred when creating the user group")
            }
        })
    }

    const handleAssignUserToGroup = ({groupID, users}, successCallback, errorCallback) => {
        assignUserToGroup({
            group_id:groupID,
            users:users.map((user)=>{
                return {
                    user_id:user.value
                }
            })
        }, (status)=>{
            if([201, 200].includes(status)) {
                successCallback()
            }else{
                errorCallback("An error occurred when assigning users to this user group")
            }
        })
    }

    return {
        getUsersForSelectDropdown,
        createUserGroupsAndAssignUsers,
        handleAssignUserToGroup
    }


};

export default useLicensingUsers;
