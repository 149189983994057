import React, {useEffect, useState} from 'react';
import Async, { useAsync } from 'react-select/async';
import useLicensingUsers from "../../hooks/useLicensingUsers";
import {getTableSizes} from "../../hooks/helpers";
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";
import './AddLicensingUserGroup.css'
import {useDebounce} from "../../hooks/useDebounce";
import EmptyTableDataInfographic from "../EmptyTableDataInfographic/EmptyTableDataInfographic";
import dayjs from "dayjs";
import {getLicensingUsersListInGroup} from "../../hooks/utils/licensing";

const AddLicensingUserGroup = ({setFocused, group=null, setRefresher=()=>{}}) => {

    const TABLE_SIZES = getTableSizes();
    const [search, setSearch] = useState('');
    const [size, setSize] = useState(TABLE_SIZES[0]);
    const [page, setPage] = useState(1);
    const [fields, setFields] = useState({
        groupName:group?.name || "",
        users:[]
    })
    const [notification, setNotification] = useState({
        success:null,
        msg:null
    })
    const handleSetNotification = (success, msg) => {
        setNotification({
            msg: msg,
            success
        })
        setTimeout(()=>{
            setNotification({})
        }, 2500)
    }
    const {getUsersForSelectDropdown, createUserGroupsAndAssignUsers, handleAssignUserToGroup} = useLicensingUsers({search, size, page})
    const [submitting, setSubmitting] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [searching, setSearching] = useState(false)
    const [searchedUsers, setSearchedUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [allSearchedUserChecked, setAllSearchedUserChecked] = useState(false)

    const [groupUsersLoading, setGroupUsersLoading] = useState(false)

    const handleUserSearch = () => {
        setAllSearchedUserChecked(false)
        if(searchTerm.length < 2) {
            setSearchedUsers([])
            return
        }
        setSearching(true)
        getUsersForSelectDropdown(searchTerm, (data)=>{
            const result = data?.results
            if(Array.isArray(result) && result.length > 0){
                setSearchedUsers(result.filter((pre)=>pre.name!==""))
            }else{
                setSearchedUsers([])
            }
            setSearching(false)
        })
    }

    const handleRetrieveUser = (userID) => {
        setSelectedUsers((old)=>old.filter((user)=>user?.user_id!==userID))
    }

    const userIsAlreadySelected = (user) => {
       return selectedUsers.findIndex((pre)=>pre.user_id===user.user_id)!==-1
    }

    const handleSearchedUserCheck = (user) => {
        if(userIsAlreadySelected(user)){
            //checked
            handleRetrieveUser(user.user_id)
        }else{
            handleAddUserToSelectedUsers(user)
        }
    }

    const handleAllSearchedUsersCheck = () => {
        if(allSearchedUserChecked){
            handleRetrieveAllSearchedUsersToSelectedUsers()
            setAllSearchedUserChecked(false)
        }else{
            handleAddAllSearchedUsersToSelectedUsers()
            setAllSearchedUserChecked(true)
        }
    }

    const handleAddAllSearchedUsersToSelectedUsers = () => {
        if(searchedUsers.length > 0){
            searchedUsers.forEach((user)=>{
                handleAddUserToSelectedUsers(user)
            })
        }
    }

    const handleRetrieveAllSearchedUsersToSelectedUsers = () => {
        if(searchedUsers.length > 0){
            searchedUsers.forEach((user)=>{
                handleRetrieveUser(user.user_id)
            })
        }
    }

    const handleAddUserToSelectedUsers = (user) => {
        if(!userIsAlreadySelected(user)){
            setSelectedUsers((old)=>[
                user,
                ...old
            ])
        }
    }

    useDebounce(handleUserSearch, 200, [searchTerm])

    useDebounce(()=>{
        if(selectedUsers.length === 0){
            setAllSearchedUserChecked(false)
        }
    }, 200, [selectedUsers])

    useEffect(() => {
        if(group){
            console.log({group})
            setGroupUsersLoading(true)
            getLicensingUsersListInGroup( {
                group_id:group?.group_id,
            }, (data)=>{
                setSelectedUsers(Array.isArray(data?.results) ? data.results.map((user)=>({
                    user_id:user.user_id,
                    name:user?.edx_data?.name,
                    profile_image:user?.public_metadata?.public_metadata
                })) : [])
                setGroupUsersLoading(false)
            }, ()=>{
                setSelectedUsers([])
                setGroupUsersLoading(false)
            })
        }
    }, [group]);

    const promiseOptions = (inputValue) => {
        return new Promise((resolve)=>{
            getUsersForSelectDropdown(inputValue, (data)=>{
                const result = data?.results
                if(Array.isArray(result) && result.length > 0){
                    resolve(result.filter((pre)=>pre.name!=="").map((user)=>{
                        return {
                            value:user.user_id, label:user.name
                        }
                    }))
                }else{
                    resolve([])
                }
            })
        })
    }

    const handleMultiSelectChange = (choice) => {
        setFields({
            ...fields,
            users:[...choice]
        })
    }

    const handleSuccess = () => {
        setSubmitting(false)
        handleSetNotification(true, !!group ? "Users updated!" : "Group created successfully!")
        setTimeout(()=>{
            setRefresher(Date.now())
            setFocused(false)
        }, 2000)
    }

    const handleError = (msg) => {
        setSubmitting(false)
        handleSetNotification(true,  "An error occurred. Please try again!")
    }

    const handleSubmit = (e) => {
        setSubmitting(true)
        const updatedFields = {...fields, users:selectedUsers.map((user)=>({value:user?.user_id}))}
        if(!!group){
            handleAssignUserToGroup({groupID:group?.group_id, users:updatedFields?.users}, handleSuccess, handleError)
        }else{
            createUserGroupsAndAssignUsers(updatedFields, handleSuccess, handleError)
        }
    }

    return (
        <div style={{display:"flex"}} className="popup_div-nm_popup enroll-programs-modal active">
            <div className={`popup_cont-sk ${selectedUsers.length > 0 ? "sidebar-available" : ""}`}>
                <div className="hf_popup program-enroll-popup active with-sidebar">
                    <div className="div-block-147 new_msg">
                        <div
                            className="text-block-135 mg-0">{!!group ? `Update users of ${group?.name}` : "Add a group"}</div>
                        {
                            notification?.msg && (
                                <div className={`license-notification-block ${!notification?.success ? "error" : ""}`}>
                                    {notification?.msg}
                                </div>
                            )
                        }
                    </div>
                    <div className="div-block-228">
                        <div className="form-block-7 w-form">
                            <form id="email-form-5" name="email-form-5" data-name="Email Form 5" method="get"
                                  data-wf-page-id="66e1c2afd7d305ab933f7a97"
                                  data-wf-element-id="5f64487e-caf3-b6dd-b426-6888986a7eb7"><label htmlFor="name-2"
                                                                                                   className="text-block-139-bd-f-soc">Group
                                Name *</label>
                                <input value={fields?.groupName} onChange={!group ? e => setFields({
                                    ...fields,
                                    groupName: e?.target?.value
                                }) : () => {
                                }} disabled={!!group} readOnly={!!group}
                                       className="text-field-3 w-input license-name-input" maxLength="256" name="name-2"
                                       data-name="Name 2" placeholder="Group name" type="text"
                                       id="name-2"/>
                                <label htmlFor="name-2"
                                       className="text-block-139-bd-f-soc">
                                    Users *</label>
                                <div className="w-layout-hflex flex-block-14">{!searching
                                    ?
                                    <img src="/images/search-1.svg"
                                         loading="lazy" alt=""
                                         className="image-143"/>
                                    :
                                    <span style={{width:"17px"}}><IBLSpinLoader size={17} color={"#FFF"}/></span> }<input
                                    className="text-field-10 w-input" value={searchTerm}
                                    onChange={e => setSearchTerm(e?.target?.value)} maxLength="256" name="field-6"
                                    data-name="Field 6"
                                    placeholder="Search by name, username, email..." type="text" id="field-6"/></div>
                                <div react-component="table" className="table user-search-table">
                                    {
                                        searchedUsers.length > 0 &&  (

                                            <>
                                                <div className="th">
                                                    <div className="tc _10 centered">
                                                        <div
                                                            className="div-block-2 options options_trigger no-margin centered">
                                                            <div className="w-embed"><label><input checked={allSearchedUserChecked}
                                                                                                   onChange={handleAllSearchedUsersCheck} type="checkbox"
                                                                                                   id="toggle"/><span></span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tc _60 centered">
                                                        <div className="w_sort">
                                                            <div className="text-block-134 fa_icon sort hidden"></div>
                                                            <div className="sm">Full Name</div>
                                                        </div>
                                                    </div>
                                                    <div className="tc _30 centered">
                                                        <div className="w_sort">
                                                            <div className="sm">Date Added</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    searchedUsers.map((user) => (
                                                        (
                                                            <div className="tr " onClick={()=>handleSearchedUserCheck(user)}>
                                                                <div className="tc tcell _10 no-pad">
                                                                    <div
                                                                        className="div-block-2 options options_trigger no-margin centered">
                                                                        <div className="w-embed"><label><input
                                                                            type="checkbox" id="toggle"
                                                                            checked={selectedUsers.findIndex((pre)=>pre.user_id===user.user_id)!==-1}
                                                                        /><span></span></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div data-w-id="03fefb47-44d1-aba8-5c75-91d1533071dc"
                                                                     className="tc tcell _60 no-pad-y">
                                                                    <a href="#" onClick={e=>e.preventDefault()} className="tl-2 np"><span
                                                                        className="nb">{user?.name}</span></a>
                                                                </div>
                                                                <div className="tc tcell _30 no-pad-y">
                                                                    <div className="m smaller">{user?.added_on ? dayjs(user?.added_on).format("MMM DD, YYYY") : "-"}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    ))
                                                }
                                                {
                                                    searchTerm && searchedUsers.length === 0 && !searching && <EmptyTableDataInfographic label={"No user found"} />
                                                }
                                            </>
                                        )
                                    }
                                </div>

                            </form>
                            <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                    </div>
                    <div className="div-block-147 new_msg">
                        <div className="div-block-264 f-w">
                            <div onClick={() => setFocused(false)} data-w-id="5f64487e-caf3-b6dd-b426-6888986a7ec6"
                                 className="div-block-207 first active follow-user alt-btn cancel-program-enroll">
                                <div className="text-block-111 red-text cf">Cancel</div>
                            </div>
                            <div onClick={!submitting ? handleSubmit : () => {
                            }} data-w-id="5f64487e-caf3-b6dd-b426-6888986a7ec9"
                                 className="div-block-207 first active follow-user redeem-btn mg-l16 program-enroll">
                                <div className="text-block-111 red-text cf">{group ? "Update" : "Add"}</div>
                                {submitting && <span style={{marginLeft: "5px"}}> <IBLSpinLoader size={15}
                                                                                                 color={"#FFF"}/> </span>}
                            </div>
                        </div>
                    </div>
                    <div className="w-layout-vflex enroll-notification-block"><img src="/images/check_2.png"
                                                                                   loading="lazy" sizes="100vw"
                                                                                   srcSet="images/check_2-p-500.png 500w, images/check_2.png 512w"
                                                                                   alt="" className="image-141"/>
                        <h4 className="heading-4">Program enrolled successfully</h4>
                        <div data-w-id="5f64487e-caf3-b6dd-b426-6888986a7ed0"
                             className="div-block-207 first active follow-user alt-btn cancel-program-enroll">
                            <div className="text-block-111 red-text cf">Close</div>
                        </div>
                    </div>
                    {
                        selectedUsers.length > 0 && (
                            <div className="w-layout-vflex user-search-preview-block">
                                <div className="w-layout-hflex flex-block-26">
                                    <h5 className="user-search-preview-heading">Users Preview ({selectedUsers.length})</h5>
                                    {
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                            setSelectedUsers([])
                                        }} className="link-5">Clear all</a>
                                    }
                                </div>
                                <div className="w-layout-hflex flex-block-25">
                                    <div className={`w-layout-vflex user-search-preview-content ${selectedUsers.length > 12 && searchedUsers.length ===0 ? "more-pad" : ""}`}>
                                        {
                                            selectedUsers.map((user, index) => (
                                                <div title={user.name} key={`user-search-preview-box-${index}`}
                                                     className="w-layout-vflex user-search-preview-box">
                                                    <div className="div-block-638" style={{backgroundImage:`url(${user?.profile_image ?? "/images/user-default-avatar.png"})`}}></div>
                                                    <div className="text-block-185">{user?.name}</div>
                                                    <div onClick={() => handleRetrieveUser(user?.user_id)}
                                                         className="w-layout-hflex pathway-content-box-closer user-preview-closer">
                                                        <img
                                                            src="/images/x-5.svg" loading="lazy" alt=""
                                                            className="image-149"/>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default AddLicensingUserGroup;