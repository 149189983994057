import {api, clearCookies, getIBLSEARCHURL} from '@iblai/ibl-web-react-common';
import avatarImg from '../assets/images/default_avatar.png';
import course1Img from '../assets/images/courses/c1s.jpeg';
import course2Img from '../assets/images/courses/c2s.jpeg';
import course3Img from '../assets/images/courses/c3s.jpeg';
import course4Img from '../assets/images/courses/c4s.jpeg';
import course5Img from '../assets/images/courses/c5s.jpeg';
import course6Img from '../assets/images/courses/c6s.jpeg';
import course7Img from '../assets/images/courses/c7s.jpeg';
import course8Img from '../assets/images/courses/c8s.jpeg';
import dayjs from 'dayjs';
import { actionTypes } from '../views/content/InvitationsViewContent/InvitationsReducer';
import {
  getAuthoringCourses,
  getAuthoringPrograms,
  getAuthoringPlatforms,
} from './utils/studio';
import axios from "axios";

export function getUserId() {
  let userData = JSON.parse(localStorage.getItem('userData'));
  return userData.user_id;
}

export function getUserName() {
  let userData = JSON.parse(localStorage.getItem('userData'));
  return userData.user_nicename;
}

export function getTenant() {
  // return "main"
  return localStorage.getItem('tenant');
}

export function getTodayFormattedDate() {
  /*const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(today.getDate()).padStart(2, "0");
    
      return `${year}-${month}-${day}`;*/
  return dayjs().format('YYYY-MM-DD');
}

export const beginningDateRange = {
  endDate: getTodayFormattedDate(),
  startDate: 'BEGINNING',
};

export function getLastMonthDateRange() {
  var today = new Date();
  var lastMonthStartDate = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  var lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);

  var lastMonthStartDateString = lastMonthStartDate.toISOString().split('T')[0];
  var lastMonthEndDateString = lastMonthEndDate.toISOString().split('T')[0];

  return {
    startDate: lastMonthStartDateString,
    endDate: lastMonthEndDateString,
  };
}

export function getAllTimeDateRange(data) {
  const startDate = (Object.keys(data?.data || {}) || [
    dayjs().format('YYYY-MM-DD'),
  ])[0];
  const allTimeLength = Object.keys(data?.data || {}).length;
  const endDate = (Object.keys(data?.data || {}) || [
    dayjs().format('YYYY-MM-DD'),
  ])[allTimeLength === 0 ? allTimeLength : allTimeLength - 1];
  return {
    startDate,
    endDate,
  };
}

export function getLastWeekDateRange() {
  var today = new Date();
  var lastWeekStartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay() - 6
  );
  var lastWeekEndDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay()
  );

  var lastWeekStartDateString = lastWeekStartDate.toISOString().split('T')[0];
  var lastWeekEndDateString = lastWeekEndDate.toISOString().split('T')[0];

  return {
    startDate: lastWeekStartDateString,
    endDate: lastWeekEndDateString,
  };
}

function generateDateList(startDate, endDate) {
  const dateList = [];
  const currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dateList.push(currentDate.toISOString().split('T')[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateList;
}

export function getDataWithinDateRange(data, dateRange) {
  const filteredData = {};
  const startDate = dateRange.startDate;
  const endDate = dateRange.endDate;

  // Create an array of all dates within the range
  const dateList = generateDateList(startDate, endDate); // Removed 'this.'

  // Initialize total
  let total = 0;

  // Iterate over the dates and populate the filtered data
  dateList.forEach((date) => {
    filteredData[date] = data?.data[date] || 0;
    total += filteredData[date];
  });

  return { data: filteredData, total };
}

export function secondsToHoursAndMinutes(_seconds, _format = false) {
  const hours = Math.floor(_seconds / 3600);
  const minutes = Math.floor((_seconds % 3600) / 60);
  const seconds = _seconds % 60;

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  if (_format) {
    return `${formattedHours}h ${formattedMinutes}min`;
  }
  return `${formattedHours}:${formattedMinutes}`;
}

function getRandomIntInclusive(min, max) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
}

export const randomDescriptiveGraphData = () => {
  const randomPercentage = getRandomIntInclusive(30, 100);
  const randomDifferentialValue = getRandomIntInclusive(10, 90);
  const randomTotal = getRandomIntInclusive(100, 200);
  return {
    meta: {
      change_range_percent: randomPercentage,
      change_last_seven_days_percent: randomPercentage,
      change_last_thirty_days_percent: randomPercentage,
      at: randomDifferentialValue,
      change_last_seven_days: randomDifferentialValue,
      change_last_thirty_days: randomDifferentialValue,
      nm_percent: randomPercentage,
      ny_percent: randomPercentage,
      n3y_percent: randomPercentage,
      nm: randomDifferentialValue,
      ny: randomDifferentialValue,
      n3y: randomDifferentialValue,
    },
    total: randomTotal,
  };
};

export const randomAverageGraphData = () => {
  return {
    data: {
      average: getRandomIntInclusive(30, 80),
    },
  };
};

export const randomVerticalGraphData = () => {
  return {
    data: [
      {
        name: 'English',
        time_spent: getRandomIntInclusive(100, 1000),
      },
      {
        name: 'Physics',
        time_spent: getRandomIntInclusive(100, 1000),
      },
      {
        name: 'Accounting',
        time_spent: getRandomIntInclusive(100, 1000),
      },
      {
        name: 'Python',
        time_spent: getRandomIntInclusive(100, 1000),
      },
    ],
  };
};

export const DESCRIPTIVE_TIMEFRAMES_OPTIONS = [
  {
    value: 'change_last_seven_days',
    label: 'Last Week',
  },
  {
    value: 'change_last_thirty_days',
    label: 'Last Month',
  },
  {
    value: 'change_range',
    label: 'All Time',
  },
];
export const PREDICTIVE_TIMEFRAMES_OPTIONS = [
  {
    value: 'nm',
    label: 'Next Month',
  },
  {
    value: 'ny',
    label: 'Next Year',
  },
  {
    value: 'n3y',
    label: 'Next 3 Years',
  },
];

export function randomLineChartGraphData(
  startDate = '2024-02-05',
  endDate = '2024-02-14'
) {
  const data = {};
  let currentDate = new Date(startDate);
  const endDateObj = new Date(endDate);

  while (currentDate <= endDateObj) {
    const dateString = currentDate.toISOString().slice(0, 10);
    data[dateString] = Math.floor(Math.random() * 100); // Generate a random value between 0 and 99
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  const total = Object.values(data).reduce((acc, value) => acc + value, 0);

  const meta = {
    total: total,
    change_range: total,
    change_last_seven_days: Math.floor(Math.random() * 10), // Random value for change last seven days
    change_last_thirty_days: Math.floor(Math.random() * 300), // Random value for change last thirty days
    change_last_seven_days_percent: Math.random().toFixed(2) * 100, // Random percent value for change last seven days
    change_last_thirty_days_percent: Math.random().toFixed(2) * 1000, // Random percent value for change last thirty days
    change_range_percent: total * 100, // Assuming the change range percent is based on the total
  };

  return { data, total, meta };
}

export const getSkillsURL = () => {
  return process.env.REACT_APP_IBL_SPA_SKILLS_URL || '#';
};

export function getImageUrl(url) {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  if (urlRegex.test(url)) {
    return url;
  }

  return avatarImg;
}

export const handleLogout = () => {
  localStorage.clear();
  clearCookies();
  window.location.href = `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/logout?redirect-to=${window.location.origin}`;
};

export const getTableSizes = () => {
  return [10, 25, 50, 100];
};

export const getStudioURL = () => {
  return process.env.REACT_APP_IBL_STUDIO_URL || '#';
};

export const getStudioProgramCourseURL = (courseID) => {
  return `${getStudioURL()}course/${courseID}`;
};

export const getRamdomCourseImg = () => {
  const images = [
    course1Img,
    course2Img,
    course3Img,
    course4Img,
    course5Img,
    course6Img,
    course7Img,
    course8Img,
  ];
  return images[Math.floor(Math.random() * images.length)];
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const defaultSPALogoData = {
  logo: process.env.PUBLIC_URL + '/images/logo.png',
  favicon: process.env.PUBLIC_URL + '/images/favicon.png',
  height: process.env.REACT_APP_IBL_LOGO_HEIGHT,
};

export const determineInputType = (userNameOrEmail) => {
  const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (emailPattern.test(userNameOrEmail)) {
    return { email: userNameOrEmail };
  } else {
    return { username: userNameOrEmail };
  }
};
const itemFunctionMapping = {
  Program: getAuthoringPrograms,
  Course: getAuthoringCourses,
  Platform: getAuthoringPlatforms,
};

export const handleInputChange = (value, dispatch, state) => {
  if (value === '') {
    dispatch({
      type: actionTypes.SET_FIELD,
      field: 'searchResults',
      value: [],
    });
    dispatch({
      type: actionTypes.SET_FIELD,
      field: 'itemsLoaded',
      value: true,
    });
    return;
  }

  itemFunctionMapping[state.invitationsTab.activeTab](value, (data) => {
    dispatch({
      type: actionTypes.SET_FIELD,
      field: 'searchResults',
      value: data.results,
    });
    dispatch({
      type: actionTypes.SET_FIELD,
      field: 'itemsLoaded',
      value: true,
    });
  });
};

export const convertCompletionData = (rawData) => {
  try {
    const overtime = rawData.data.overtime;
    const totalUserCount = rawData.data.total_user_count;

    const result = {
      data: {},
      total: rawData.data.completed_count,
      meta: {
        total: rawData.data.completed_count,
        change_range: rawData.meta.change_range,
        change_last_seven_days: rawData.meta.change_last_seven_days,
        change_last_thirty_days: rawData.meta.change_last_thirty_days,
        change_last_seven_days_percent:
          rawData.meta.change_last_seven_days_percent,
        change_last_thirty_days_percent:
          rawData.meta.change_last_thirty_days_percent,
        change_range_percent: rawData.meta.change_range_percent,
      },
    };

    Object.keys(overtime).forEach((date) => {
      result.data[date] = overtime[date];
    });

    return result;
  } catch (e) {
    return null;
  }
};

export function getUserCoursesMetaData(course_id, callback) {
  api.ibledxcourses.getCourseMeta({ course_key: course_id }, function (data) {
    callback(data);
  });
}

export const convertDataToCSV = (data, title, isTimeSpent) => {
  const headers = ['Date', title];
  const rows = Object.entries(data).map(([date, timeSpent]) => [
    date,
    isTimeSpent ? secondsToHoursAndMinutes(timeSpent) : timeSpent,
  ]);

  const csvContent = [
    headers.join(','),
    ...rows.map((row) => row.join(',')),
  ].join('\n');
  return csvContent;
};

export const downloadCSV = (csvContent, filename = 'data.csv') => {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const formatStringWithTimestamp = (inputString) => {
  const lowerCaseString = inputString.toLowerCase();
  const formattedString = lowerCaseString.replace(/ /g, '_');
  const now = new Date();
  const formattedDate = `${now.getFullYear()}_${String(now.getMonth() + 1).padStart(2, '0')}_${String(now.getDate()).padStart(2, '0')}`;
  const formattedTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
  const timestamp = `${formattedDate} ${formattedTime}`;
  return `${formattedString}_${timestamp}`;
};



export const getCourseImage = (courseId) => {
  return new Promise((resolve, reject) => {
    getUserCoursesMetaData(courseId, (metaData) => {
      const courseImage = metaData?.course_image_asset_path;
      if (courseImage) {
        resolve(`${process.env.REACT_APP_IBL_LMS_URL}${courseImage}`);
      } else {
        resolve(getRamdomCourseImg());
      }
    });
  }).catch((error) => {
    console.error(error);
    return getRamdomCourseImg();
  });
};

export const fetchCourseImages = (courses, courseImages, setCourseImages) => {
  courses.forEach((course) => {
    const courseId = course.course_id || course.course;
    if (!courseImages[courseId]) {
      getCourseImage(courseId).then((image) => {
        setCourseImages((prevImages) => ({
          ...prevImages,
          [courseId]: image,
        }));
      });
    }
  });
};

export function getAdminCoursesAPI(username) {
  return new Promise((resolve, reject) => {
    const tenant = getTenant();

    const fetchCourses = (tenantKey) => {
      return new Promise((_resolve, _reject) => {
        const requestData = {
          username: username,
          platform_key: tenantKey,
        };

        api.ibldmcourses.getUserCourses(
          requestData,
          (response) => {
            if (response?.results && Array.isArray(response?.results)) {
              _resolve(response?.results);
            } else {
              _resolve([]);
            }
          },
          (error) => {
            console.error('getUserCourses: ', error);
            _reject(error);
          }
        );
      });
    };

    fetchCourses(tenant)
      .then((courses) => resolve(courses))
      .catch((error) => reject(error));
  });
}

export function getAdminProgramsAPI(username, callback, detailCallback) {
  const tenant = getTenant();

  const fetchPrograms = (tenantKey) => {
    return new Promise((resolve, reject) => {
      const requestData = {
        username,
        platform_key: tenantKey,
      };

      api.ibldmprograms.getUserPrograms(
        requestData,
        (response) => {
          if (!response || !Array.isArray(response)) {
            resolve([]);
          } else {
            resolve(response);
          }
        },
        (error) => {
          console.error('getUserCourses: ', error);
          reject(error);
        }
      );
    });
  };

  fetchPrograms(tenant)
    .then((programs) => {
      callback(programs);
    })
    .catch(() => callback([]));
}


export function slugify(string) {
  return string
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
}