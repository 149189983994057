import {api, getUserName} from '@iblai/ibl-web-react-common';
import { beginningDateRange, getTenant } from '../helpers';

export function getLicensesListOld(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdaudience.audienceOrgsEnrollmentsOverTimeRetrieve(...args),
    getTenant(),
    beginningDateRange
  )((err, data, response) => (err, callback(data), response));
}

export const getLicensesList = (licenseType="platform", queryParam={},successCallback, errorCallback) =>{
  const currentTenant = getTenant()
  const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/licenses/${licenseType}/?platform_key=`+currentTenant
  const param = new URLSearchParams(queryParam).toString()
  fetch(`${url}&${param}`, {
    method: 'GET',
    //body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('dm_token'),
    },
  }).then((resp) => {
    resp.json().then((data) => {
      successCallback(data);
      //successCallback({results:USERS_DATASET})
    }).catch(err => {
      errorCallback()
    });
  }).catch(err => {
    errorCallback()
  });
}
export const addLicenseContent = (data, callback) =>{
  const currentTenant = getTenant()
  const url = process.env.REACT_APP_IBL_DM_URL + '/api/catalog/licenses/platform/'
  fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      ...data,
      platform_key:currentTenant
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('dm_token'),
    },
  }).then((resp) => {
    callback(resp.status)
  }).catch(err => {
    //
  });
}

export const getLicensingUserGroupList = (queryParam={},successCallback, errorCallback) =>{
  const currentTenant = getTenant()
  const url = process.env.REACT_APP_IBL_DM_URL + `/api/core/user_groups/?platform_key=`+currentTenant
  const param = new URLSearchParams(queryParam).toString()
  fetch(`${url}&${param}`, {
    method: 'GET',
    //body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('dm_token'),
    },
  }).then((resp) => {
    resp.json().then((data) => {
      successCallback(data);
      //successCallback({results:USERS_DATASET})
    }).catch(err => {
      errorCallback()
    });
  }).catch(err => {
    errorCallback()
  });
}

export const getLicensingUsersListInGroup = (queryParam={},successCallback, errorCallback) =>{
  const currentTenant = getTenant()
  const url = process.env.REACT_APP_IBL_DM_URL + `/api/core/user_groups/link/?platform_key=`+currentTenant
  const param = new URLSearchParams(queryParam).toString()
  fetch(`${url}&${param}`, {
    method: 'GET',
    //body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('dm_token'),
    },
  }).then((resp) => {
    resp.json().then((data) => {
      successCallback(data);
      //successCallback({results:USERS_DATASET})
    }).catch(err => {
      errorCallback()
    });
  }).catch(err => {
    errorCallback()
  });
}

/*
export const getUsersList = (param = {}, successCallback, errorCallback) => {
  const currentTenant = getTenant()
  const queryParam = new URLSearchParams(param).toString()
  const url = process.env.REACT_APP_IBL_DM_URL + `/api/search/users/orgs/${currentTenant}/users/${getUserName()}/?`+queryParam
  fetch(url, {
    method: 'GET',
    /!*headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('dm_token'),
    },*!/
  }).then((resp) => {
    resp.json().then((data) => {
      successCallback(data);
      //successCallback({results:USERS_DATASET})
    }).catch(err => {
      errorCallback()
    });
  }).catch(err => {
    errorCallback()
  });
}
*/

export const getUsersList = (param = {}, successCallback, errorCallback) => {
  const currentTenant = getTenant()
  const queryParam = new URLSearchParams({
    platform_key:currentTenant,
    ...param
  }).toString()
  const url = process.env.REACT_APP_IBL_DM_URL + '/api/core/platform/users?'+queryParam
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('dm_token'),
    },
  }).then((resp) => {
    resp.json().then((data) => {
      successCallback(data);
      //successCallback({results:USERS_DATASET})
    }).catch(err => {
      errorCallback()
    });
  }).catch(err => {
    errorCallback()
  });
}

export const createOrUpdateUserGroup = (data, callback) => {
  const currentTenant = getTenant()
  const url = process.env.REACT_APP_IBL_DM_URL + '/api/core/user_groups/'
  fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      ...data,
      platform_key:currentTenant,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('dm_token'),
    },
  }).then((resp) => {
    callback(resp.status)
  }).catch(err => {
    //
  });
}

export const assignUserToGroup = (data, callback) => {
  const currentTenant = getTenant()
  const url = process.env.REACT_APP_IBL_DM_URL + '/api/core/user_groups/link/bulk/'
  fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      ...data,
      platform_key:currentTenant,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('dm_token'),
    },
  }).then((resp) => {
    callback(resp.status)
  }).catch(err => {
    //
  });
}

export const assignLicense = (licenseType="user", data, callback, isGroup=false, unassign=false) => {
  const currentTenant = getTenant()
  const dataToSubmit = {
    ...data,
    platform_key:currentTenant,
  }
  const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/licenses/${licenseType}/assignment/${isGroup ? "group/" : ""}${unassign ? "?"+new URLSearchParams(dataToSubmit).toString() : ""}`
  fetch(url, {
    method: !unassign ? 'POST' : 'DELETE',
    ...(!unassign ? {
      body: JSON.stringify(dataToSubmit)
    } : {}),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('dm_token'),
    },
  }).then((resp) => {
    callback(resp.status)
  }).catch(err => {
    //
  });
}

export const getAssignmentsPerLicense = (param = {}, licenseType="user", successCallback, errorCallback, isGroup=false) => {
  const currentTenant = getTenant()
  const queryParam = new URLSearchParams({
    platform_key:currentTenant,
    ...param
  }).toString()
  const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/licenses/${licenseType}/assignment/${isGroup ? "group/" : ""}?`+queryParam
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('dm_token'),
    },
  }).then((resp) => {
    resp.json().then((data) => {
      successCallback(data);
      //successCallback({results:USERS_DATASET})
    }).catch(err => {
      errorCallback()
    });
  }).catch(err => {
    errorCallback()
  });
}

